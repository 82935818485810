/* global process */
import React, { useEffect, useMemo, useCallback, useState } from "react";
import { Helmet } from 'react-helmet';
import useMediaQuery from '@mui/material/useMediaQuery';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { ActionBar, Button, CategoryHero, ContentCard, SuccessChipsList, FeaturedArticles, NewToForward }
  from "../components";
import { linkResolver, transformFieldName } from '../utils/prismic-config';

function ResourceCategoryTemplate ({
  data: {
    allPrismicArticles: {
      pageInfo, relatedKeywords, nodes: allPrismicArticles
    },
    featuredArticles: { nodes: featuredArticles },
    mostRecentArticles: { nodes: mostRecentArticles },
    featuredNewsArticles: { nodes: featuredNewsArticles }
  },
  pageContext: {
    category
  }
}) {
  const title = `${category} Category`;
  const description = `${category} Resources`;
  const isLarge = useMediaQuery('(min-width:1600px)');
  const isDesktop = useMediaQuery('(min-width:1280px) and (max-width:1599px)');
  const isMobile = useMediaQuery('(max-width:599px)');
  const [ articlesData, setArticlesData ] = useState([]);
  const [ relatedTerms, setRelatedTerms ] = useState([]);
  const [ featTag, setFeatTag ] = useState('featured');
  const [ showAdditionalArticles, setShowAdditionalArticles ] = useState(0);

  const handleTagClick = useCallback((val, ev) => {
    ev.preventDefault();
    setFeatTag(val);
  }, []);

  const articlesPerSection = 6;

  const featArticles = useMemo(() => {
    if (featuredArticles.length > 0 && featTag === 'featured') {
      return featuredArticles.slice(0, articlesPerSection);
    }

    if (mostRecentArticles.length > 0 && featTag === 'most-recent') {
      return mostRecentArticles.slice(0, articlesPerSection);
    }

    if (allPrismicArticles.length > 0 && featTag === 'all') {
      return allPrismicArticles.slice(0, articlesPerSection);
    }

    console.warn(`Invalid featTag: ${featTag}`);
  }, [ featTag, articlesPerSection, allPrismicArticles, featuredArticles, mostRecentArticles ]);

  const filteredArticles = useMemo(() => {
    const terms = new Set(relatedTerms.reduce((memo, t) => {
      if (t.clicked) memo.push(t.label);
      return memo;
    }, []));

    let theArticles = allPrismicArticles;

    if (terms.size) {
      theArticles = allPrismicArticles.filter((article) => {
        for (let { keyword } of (article.data.keywords ?? [])) {
          if (terms.has(keyword)) return true;
        }

        return false;
      });
    }

    return (
      theArticles.slice(0, articlesPerSection + showAdditionalArticles)
    );
  }, [ allPrismicArticles, articlesPerSection, showAdditionalArticles, relatedTerms ]);

  const hasMore = useMemo(() => {
    if (!filteredArticles) return false;

    return filteredArticles.length < allPrismicArticles.length;
  }, [ allPrismicArticles, filteredArticles ]);

  const handleLoadMore = useCallback(() => {
    setShowAdditionalArticles(showAdditionalArticles + articlesPerSection);
  }, [ articlesPerSection, showAdditionalArticles ]);

  const clickableChipsArr = useMemo(() => {
    return relatedKeywords.map((item) => {
      return { label: item };
    });
  }, [ relatedKeywords ]);

  const renderArticles = (arr) => {
    return (
      arr?.map( (article, index) => (
        <div key={index} className='logo-image'>
          <ContentCard
            headline={ article.data.title.text }
            category={ article.data.category }
            image={ article.data.image }
            readTime={ article.data.readTime }
            bodyCopy={ article.data.description.text }
            dateStamp={ article.first_publication_date }
            featured={ article.tags.includes('featured') ? true : false }
          >
            <ActionBar
              dateStamp={ article.first_publication_date }
              readTime={ article.data.readTime }
            />
          </ContentCard>
        </div>
      ))
    );
  };

  useEffect(() => {
    let articlesDataMap = [];
    featuredNewsArticles.map(item => {
      const article = {
        article: {
          type: item.data.category,
          slug: item.data.title.text,
          first_publication_date: item.data.publishDate,
          url: item.url
        }
      };
      articlesDataMap.push(article);
    });
    setArticlesData(articlesDataMap);
  }, [ featuredNewsArticles ]);

  const relatedKeywordsRendered = () => {
    return (
      <div className="related-keywords">
        <SuccessChipsList
          header="Related Keywords"
          initialState={ clickableChipsArr }
          onChange={ setRelatedTerms }
        />
      </div>
    );
  };

  const fwrdFiltersSection = () => {
    return (
      <section className="fwrd-collection-filters">
        <div className="filter-border">
          <div className="filter-wrapper">
            <ul className="inner-wrapper">
              <li className="tag-link-title">
                <a
                  className={`tag-link ${featTag === 'featured' ? 'active' : ''}`}
                  onClick={handleTagClick.bind(null, 'featured')}
                  onKeyPress={handleTagClick.bind(null, 'featured')}
                  role='tab'
                  tabIndex="0"
                >Featured</a>
              </li>
              <li className={ `tag-link-title` }>
                <a
                  className={`tag-link ${featTag === 'most-recent' ? 'active' : ''}`}
                  onClick={handleTagClick.bind(null, 'most-recent')}
                  onKeyPress={handleTagClick.bind(null, 'most-recent')}
                  role='tab'
                  tabIndex="0"
                >Most Recent</a>
              </li>
              <li className={ `tag-link-title` }>
                <a
                  className={`tag-link ${featTag === 'all' ? 'active' : ''}`}
                  onClick={handleTagClick.bind(null, 'all')}
                  onKeyPress={handleTagClick.bind(null, 'all')}
                  role='tab'
                  tabIndex="0"
                >All</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    );
  };

  const paginationLoadMore = () => {
    return (
      <div className="pagination-bar">
        <Button
          variant="text"
          className={`load-more-btn visible-${hasMore}`}
          disableRipple
          onClick={ handleLoadMore }
        >
          Load More
        </Button>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="og:title" content={title} />
        <meta name="description" content={description} />
        <meta name="og:description" content={description} />
      </Helmet>
      <CategoryHero
        title={ category }
      />
      <div className="fwrd-resources-container --dark">
        <div className="container --small fwrd-resources-container-sizing">
          { fwrdFiltersSection() }
          <div className="fwrd-layout-column-two ending-section">
            <div className="column-layout-flex">
              <div className="layout-column column-main">
                <div className="articles-well small-grid first-section">
                  { renderArticles(featArticles) }
                </div>

                { relatedKeywordsRendered() }

                <div className="articles-well small-grid">
                  { renderArticles(filteredArticles) }
                </div>
                <div className="articles-well no-grid">
                  { paginationLoadMore() }
                </div>
              </div>
              <div className="layout-column column-news constrain-column">
                <NewToForward />
                <FeaturedArticles articles={ articlesData } />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const query = graphql `
query ResourceCategoryQuery($category: String!, $skip: Int!) {
  allPrismicArticles(
    filter: {data: {category: {eq: $category}, isNews: {eq: false}}}
    sort: {fields: data___publishDate, order: DESC}
    limit: 1000
    skip: $skip
  ) {
    pageInfo {
      totalCount
      perPage
      pageCount
      itemCount
      hasPreviousPage
      hasNextPage
      currentPage
    }
    relatedKeywords: distinct(field: data___keywords___keyword)

    nodes {
      ...CategoryResourcesCard
    }
  }

  featuredArticles: allPrismicArticles(
    filter: {
      data: {category: {eq: $category}}
      tags: {eq: "featured"}
    }
    sort: {fields: last_publication_date, order: DESC}
  ) {
    nodes {
      ...CategoryResourcesCard
    }
  }

  mostRecentArticles: allPrismicArticles(
    filter: {
      data: {category: {eq: $category}}
    }
    limit: 6
    sort: {fields: data___publishDate, order: DESC}
  ) {
    nodes {
      ...CategoryResourcesCard
    }
  }

  featuredNewsArticles: allPrismicArticles(
    filter: {
      data: {isNews: {eq: true}}
    }
    sort: {fields: data___publishDate, order: DESC}
    limit: 4
  ) {
    nodes {
      ...CategoryResourcesCard
    }
  }
}

fragment CategoryResourcesCard on PrismicArticles {
  _previewable
  url
  tags
  data {
    category
    title { text }
    description { text }
    image { gatsbyImageData, alt }
    publishDate
    readTime
    keywords { keyword }
  }
}
`;

export default withPrismicPreview(ResourceCategoryTemplate, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver, transformFieldName
  }
]);
